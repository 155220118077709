<template>
  <el-main>
    <el-button style="margin-bottom: 10px" size="small" type="primary" icon="el-icon-plus" @click="$router.push('/extension/pointsMall/addPointGoods')">添加商品</el-button>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="商品名称：">
        <el-input size="small" placeholder="请输入商品名称" v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="getDataList(1)">搜索</el-button>
        <el-button size="small" type="text" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="rank" align="center">
        <template #header>
          <div class="rank">
            <span>排序</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <el-input size="mini" v-model="scope.row.sort" @blur="tableChangeData(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="rank" label="商品主图" align="center">
        <template v-slot="{ row }">
          <el-image style="width: 70px; height: 70px" :src="row.picture[0]" :preview-src-list="row.picture"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="商品名称" align="center"></el-table-column>
      <el-table-column prop="rank" align="center">
        <template #header>
          <div class="rank">
            <span>积分兑换价格</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template v-slot="{ row }"><el-input @blur="tableChangeData(row)" size="mini" v-model="row.integral_amount"></el-input></template>
      </el-table-column>
      <el-table-column prop="rank" label="" align="center">
        <template #header>
          <div class="rank">
            <span>支付价格</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template v-slot="{ row }">
          <div class="updateBox">
            <span>￥</span>
            <el-input size="mini" @blur="tableChangeData(row)" v-model="row.price"></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center">
        <template #header>
          <div class="rank">
            <span>库存</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template v-slot="{ row }">
          <el-input size="mini" @blur="tableChangeData(row)" v-model="row.stock"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="rank" label="" align="center">
        <template #header>
          <div class="rank">
            <span>上/下架</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template v-slot="{ row }">
          <el-button type="primary" @click="tableChangeData(row, 1)" size="mini" v-if="!row.is_putaway">上架</el-button>
          <el-button size="mini" @click="tableChangeData(row, 1)" v-else>下架</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="rank" label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" @click="$router.push('/extension/pointsMall/editPointGoods?id=' + row.id)">编辑</el-button>
          <el-button type="text" @click="deleteGoods(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging.vue';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      ruleForm: {
        name: '',
      },
      page: 1,
      rows: 10,
      total: 0,
      dataList: [],
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    clearSearch() {
      this.ruleForm.name = '';
      this.getDataList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getDataList();
    },
    getDataList(style) {
      if (style) this.page = 1;
      let obj = {
        page: this.page,
        rows: this.rows,
      };
      if (this.ruleForm.name) obj.name = this.ruleForm.name;
      this.$axios.post(this.$api.integral.integralGoods.list, obj).then(res => {
        if (res.code === 0) {
          res.result.list.map(item => {
            item.picture = JSON.parse(item.picture);
          });
          this.dataList = res.result.list;
          this.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 点击删除商品
    deleteGoods(data) {
      this.$confirm('此操作将永久删除该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.integral.integralGoods.del, {
            id: data.id,
          });
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('删除成功');
            this.getDataList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    // 在表格中修改数据
    tableChangeData(data, type) {
      let is_putaway = data.is_putaway;
      if (type) is_putaway = data.is_putaway ? 0 : 1;
      let obj = {
        id: data.id,
        integral_amount: data.integral_amount,
        price: data.price,
        prime_cost: data.prime_cost,
        stock: data.stock,
        is_putaway,
        sort: data.sort,
      };
      this.$axios.post(this.$api.integral.integralGoods.editInfo, obj).then(res => {
        if (res.code === 0) {
          let str = '编辑成功';
          if (type) str = data.is_putaway ? '下架成功' : '上架成功';
          this.$message.success(str);
          this.getDataList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .el-table {
    /deep/ .el-input__inner {
      border-color: #fff;
      text-align: center;
    }
    /deep/ .el-input__inner:focus {
      border-color: #409eff;
    }
    .updateBox {
      display: flex;
      align-items: center;
    }
  }
}
</style>
