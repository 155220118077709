var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('el-button',{staticStyle:{"margin-bottom":"10px"},attrs:{"size":"small","type":"primary","icon":"el-icon-plus"},on:{"click":function($event){return _vm.$router.push('/extension/pointsMall/addPointGoods')}}},[_vm._v("添加商品")]),_c('el-form',{staticClass:"el-form-search",attrs:{"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"商品名称："}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入商品名称"},model:{value:(_vm.ruleForm.name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "name", $$v)},expression:"ruleForm.name"}})],1),_c('el-form-item',{attrs:{"label":" ","label-width":"25px"}},[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.getDataList(1)}}},[_vm._v("搜索")]),_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":_vm.clearSearch}},[_vm._v("清空搜索条件")])],1)],1),_c('el-table',{attrs:{"data":_vm.dataList,"header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"prop":"rank","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"rank"},[_c('span',[_vm._v("排序")]),_c('i',{staticClass:"el-icon-edit-outline"})])]},proxy:true},{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"size":"mini"},on:{"blur":function($event){return _vm.tableChangeData(scope.row)}},model:{value:(scope.row.sort),callback:function ($$v) {_vm.$set(scope.row, "sort", $$v)},expression:"scope.row.sort"}})]}}])}),_c('el-table-column',{attrs:{"prop":"rank","label":"商品主图","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"70px","height":"70px"},attrs:{"src":row.picture[0],"preview-src-list":row.picture}})]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"商品名称","align":"center"}}),_c('el-table-column',{attrs:{"prop":"rank","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"rank"},[_c('span',[_vm._v("积分兑换价格")]),_c('i',{staticClass:"el-icon-edit-outline"})])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini"},on:{"blur":function($event){return _vm.tableChangeData(row)}},model:{value:(row.integral_amount),callback:function ($$v) {_vm.$set(row, "integral_amount", $$v)},expression:"row.integral_amount"}})]}}])}),_c('el-table-column',{attrs:{"prop":"rank","label":"","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"rank"},[_c('span',[_vm._v("支付价格")]),_c('i',{staticClass:"el-icon-edit-outline"})])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"updateBox"},[_c('span',[_vm._v("￥")]),_c('el-input',{attrs:{"size":"mini"},on:{"blur":function($event){return _vm.tableChangeData(row)}},model:{value:(row.price),callback:function ($$v) {_vm.$set(row, "price", $$v)},expression:"row.price"}})],1)]}}])}),_c('el-table-column',{attrs:{"align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"rank"},[_c('span',[_vm._v("库存")]),_c('i',{staticClass:"el-icon-edit-outline"})])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini"},on:{"blur":function($event){return _vm.tableChangeData(row)}},model:{value:(row.stock),callback:function ($$v) {_vm.$set(row, "stock", $$v)},expression:"row.stock"}})]}}])}),_c('el-table-column',{attrs:{"prop":"rank","label":"","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"rank"},[_c('span',[_vm._v("上/下架")]),_c('i',{staticClass:"el-icon-edit-outline"})])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.is_putaway)?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.tableChangeData(row, 1)}}},[_vm._v("上架")]):_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.tableChangeData(row, 1)}}},[_vm._v("下架")])]}}])}),_c('el-table-column',{attrs:{"prop":"rank","label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$router.push('/extension/pointsMall/editPointGoods?id=' + row.id)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteGoods(row)}}},[_vm._v("删除")])]}}])})],2),_c('Paging',{attrs:{"total":_vm.total,"page":_vm.page,"pageNum":_vm.rows},on:{"updatePageNum":_vm.updateData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }